import * as React from "react"
import { Link } from 'gatsby'
import Layout from "../templates/layout-standard"
import Seo from "../components/seo"

const NotFoundPage = ({ location }) => (
  <Layout location={location} heading1="Oops!" heading2="We can&rsquo;t seem to find the page you&rsquo;re looking for.">
  <Seo title="404: Not found" meta={[{description: 'Sorry, the page you requested could not be found.'}]} bodyclass="meet-with-us subpage" />
  <div className="constrained">
    <div className="content-constrained">
      <div className="flexwrap_subpage">
        <main>
          <p>Please return to the <Link to="/">home page</Link>.</p>
        </main>
      </div>
    </div>
  </div>
  </Layout>
)

export default NotFoundPage
